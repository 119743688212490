import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { useServicesHome } from '../../hooks/getServicesHome';

const Services = () => {
    const servicesData = useServicesHome();
    const services = servicesData.wordpressPage.acf.service_boxes_list;
    return (
        <section className="homethreeboxes page-section bg-primary text-white">
            <img className="img-servie-award" src="https://werockthespectrumwellesley.wrtsfranchise.com/wp-content/uploads/2024/06/Best-of-Boston-Logo.jpg" alt="award img" />
            <img className="img-servie-award2" src="https://werockthespectrumwellesley.wrtsfranchise.com/wp-content/uploads/2024/07/original-303C794D-CF10-4C7D-AE85-DFE740DAB623.jpeg" alt="award img" />
            <div className="container flexwrap">
                {services.map(service => (
                    <div className="hbox flexbox" key={service.service_title}>
                        <Img
                            fluid={
                                service.service_image.localFile.childImageSharp
                                    .fluid
                            }
                            className="whiteborderimg"
                        />
                        <h3
                            dangerouslySetInnerHTML={{
                                __html: service.service_title,
                            }}
                        />
                        <p
                            dangerouslySetInnerHTML={{
                                __html: service.service_description,
                            }}
                        />
                        <Link
                            to={service.service_link}
                            className="wrtsbtn yellowbtn"
                        >
                            MORE INFO
                        </Link>
                    </div>
                ))}
            </div>
        </section>
    );
};
export default Services;
